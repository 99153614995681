<template>
    <div class="credits-my">
        <div class="page-header">
            <el-row>
                <el-col :span="24">
                    <div class="header-desc">
                        <router-link to><el-button icon="el-icon-arrow-left" round @click="$router.back(-1)">{{ $t('data.public.back') }}</el-button></router-link>
                    </div>
                </el-col>
            </el-row>
        </div>


        <el-row :gutter="20">
            <el-col :span="24">
                <h2 class="mb-20" align="center">{{ $t('data.credits.remainingCredits') }}:{{credit}}</h2>
            </el-col>
        </el-row>

        <el-row :gutter="20">

            <el-col :span="24">
                <el-tabs type="border-card">
                    <el-tab-pane :label="$t('data.public.topUp')" v-if="topupData[0]">
                        <div class="topup-top">
                            <h3>{{ $t('data.credits.pricing') }}</h3>
                            <p>eCert, 1 {{ $t('data.credits.issue') }} = {{ issueCredit }} {{ $t('data.credits.credits') }}</p>
                        </div>
                        <div class="top-up-wrap">
                            <el-row :gutter="20">
                                <el-col :span="4" class="mb-10">
                                    <div class="explain-wrap">
                                        <div class="explain-top">
                                            <p>{{ $t('data.public.topUp') }} {{ $t('data.credits.pricing') }}<br>{{ $t('data.credits.price') }} | {{ $t('data.credits.usd') }}</p>
                                        </div>
                                        <div class="explain-content">
                                            <div class="item-explain">
                                                <p>{{ $t('data.credits.credits') }}</p>
                                            </div>
                                            <div class="item-explain">
                                                <p>{{ $t('data.credits.issues') }}</p>
                                            </div>
                                            <div class="item-explain">
                                                <p>1 {{ $t('data.credits.credits') }} = {{ $t('data.credits.usd') }}</p>
                                            </div>
                                            <div class="item-explain">
                                                <p>1 {{ $t('data.credits.issues') }} = {{ $t('data.credits.usd') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col class="pricing-color" :span="5" v-for="(item, index) in topupData" :key="index">
                                    <div class="pricing-wrap">
                                        <div class="pricing-top">
                                            <p>{{item.name}}<br>${{item.price}}</p>
                                        </div>
                                        <div class="pricing-content">
                                            <div class="item-pricing">
                                                <p>{{item.credit}}</p>
                                            </div>
                                            <div class="item-pricing">
                                                <p>{{item.issue_limit}}</p>
                                            </div>
                                            <div class="item-pricing">
                                                <p>{{item.credit_for_usd}}</p>
                                            </div>
                                            <div class="item-pricing">
                                                <p>{{item.issue_for_usd}}</p>
                                            </div>
                                            <div class="item-pricing-btn">
                                                <el-button type="primary" round @click="openBasic(index,item.picture)" size="mini">{{ $t('data.public.topUp') }}</el-button>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane :label="$t('data.credits.paymentHistory')">
                        <el-table
                            :data="paymentList"
                            style="width: 100%">
                            <el-table-column
                                prop="date"
                                :label="$t('data.credits.table.date')">
                            </el-table-column>
                            <el-table-column
                                prop="time"
                                :label="$t('data.credits.table.time')">
                            </el-table-column>
                            <el-table-column
                                prop="package.name"
                                :label="$t('data.credits.table.package')">
                            </el-table-column>
                            <el-table-column
                                prop="price"
                                :label="$t('data.credits.table.amount')">
                            </el-table-column>
                            <el-table-column
                                prop="status"
                                :label="$t('data.credits.table.status')">
                                 <template #default="scope">
                                    <el-tag v-if="scope.row.status === 1" type="warning">{{ $t('data.credits.paymentStatus.waiting') }}</el-tag>
                                    <el-tag v-else-if="scope.row.status === 2" type="success">{{ $t('data.credits.paymentStatus.successful') }}</el-tag>
                                    <el-tag v-else type="danger">{{ $t('data.credits.paymentStatus.failure') }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('data.public.action')" align="right">
                                <template #default="scope">
                                    <el-button
                                    v-if="scope.row.status !== 3"
                                    type="primary"
                                    size="mini"
                                    @click="toInvoice(scope.$index, scope.row)">{{$t('data.credits.invoice')}}</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('data.credits.creditRedeemHistory')">
                        <el-table
                            :data="creditLogs"
                            style="width: 100%">
                            <el-table-column
                                prop="date"
                                :label="$t('data.credits.table.date')">
                            </el-table-column>
                            <el-table-column
                                prop="time"
                                :label="$t('data.credits.table.time')">
                            </el-table-column>
                            <el-table-column
                                prop="branch.name"
                                :label="$t('data.credits.table.branchName')">
                            </el-table-column>
                            <el-table-column
                                prop="operator.name"
                                :label="$t('data.credits.table.account')">
                            </el-table-column>
                            <el-table-column
                                prop="cert.topic"
                                :label="$t('data.credits.table.certName')">
                            </el-table-column>
                            <el-table-column
                                prop="credit_redeemed"
                                :label="$t('data.credits.table.creditRedeemed')">
                            </el-table-column>
                            <el-table-column
                                prop="cert_count"
                                :label="$t('data.credits.table.publishedCerts')">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </el-col>

        
        </el-row>
        <div class="topup-dialog">
            <el-dialog
                v-model="dialogVisible"
                width="25%">

                <div class="top-up-content">
                    <img :src="src" alt="">
                    <div class="top-up-btn">
                        <el-button @click="dialogVisible = false">{{$t('data.public.cancel')}}</el-button>
                        <el-button type="primary" @click="toPayment"  :class="btnColor">{{$t('data.credits.proceedPayment')}}</el-button>
                    </div>
                </div>

                <!-- <template #footer>
                    <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="dialogVisible = false">Proceed to Payment</el-button>
                    </span>
                </template> -->
            </el-dialog>
        </div>

    </div>
</template>    

<script>

import { pricingList,pricingBuy,branchCreditLogs,rechargesList,getCredit,getBase } from '../../api/index'
import { ElNotification } from 'element-plus'
export default {
    name: 'credits-my',
    data (){
        return {
            credit: null,
            topupData: [],
            paymentList: [],
            creditLogs: [],
            dialogVisible: false,
            src: '/image/ecert-img.jpg',
            btnColor: '',
            topupActive: null,
            centerDialogVisible:true,
            issueCredit: null
        }
    },
    mounted() {
        this.ininPageData();
        if(this.$route.query.success == 1){
            ElNotification({
                title: this.$t('data.public.success'),
                message: this.$t('data.public.orderCompleted'),
                type: 'success',
                duration: 0,
            })
        }
        
    },
    methods: {
        ininPageData(){
            let apiData = {};
            pricingList({apiData}).then( res => {
                this.topupData = res.data
            });
            rechargesList({}).then( res => {
                this.paymentList = res.data;
            });
            getCredit({}).then( res => {
                this.credit = res.data.credit
            });
            branchCreditLogs({}).then( res => {
                this.creditLogs = res.data;
            });
            getBase({}).then( res => {
                this.issueCredit = res.data.issue_credit;
            });
        },
        openBasic(val,url){
            this.topupActive = val;
            val++;
            if(val === 1){
                this.btnColor = 'light-green-btn';
            }else if(val === 2){
                this.btnColor = 'cambridge-blue-btn';
            }else if(val === 3){
                this.btnColor = 'dark-blue-btn';
            }else if(val === 4){
                this.btnColor = 'purple-btn';
            }
            this.src = url;
            this.dialogVisible = true;
        },
        toInvoice(index, row){
            this.$router.push('/credits/invoice/'+row.id);
        },
        toPayment(){
            let pricingId = this.topupData[this.topupActive].id;
            let apiData = {
                id: pricingId,
                redirect_url: 'http://techevent2.com2.hk/credits/pricing'
            };
            pricingBuy({apiData}).then( res => {
                window.open(res.data.payment_data.url, '_self');
            });
            this.dialogVisible = false;

        }
    }
}
</script>

<style scoped>
::v-deep(.el-col-25){
    width: 20%;
}
.topup-top{
    margin-bottom: 30px;
}
.topup-top > h3{
    text-align: center;
    font-size: 31px;
    margin-bottom: 10px;
    color: #6222EE;
}   
.topup-top > p{
    font-size: 16px;
    color: #666666;
}


.top-up-wrap{
    max-width: 1200px;
    margin: 0 auto;
}
.explain-wrap{
    background-color: #224486;
    border-radius: 30px 0px 15px 0px;
    padding: 15px;
    text-align: right;
    color: #fff;
}

.explain-top{
    padding: 15px 10px 10px 10px;
}
.explain-top p{
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
}
.explain-content{
    padding: 15px 0px 58px 0px;
    display: inline-flex;
    flex-direction: column;
}
.item-explain{
    font-size: 14px;
    line-height: 18px;
    padding: 5px;
    border-bottom: 1px solid #BFBFBF;
    font-weight: 400;
}

.pricing-wrap{
    border-radius: 30px 0px 15px 15px;
    padding: 15px;
    text-align: center;
    color: #fff;
}
.pricing-color:nth-child(2) .pricing-wrap{
    background: -webkit-linear-gradient(#03D4C8,#008FD7);
    background: -o-linear-gradient(#03D4C8,#008FD7);
    background: -moz-linear-gradient(#03D4C8,#008FD7);
    background: -mos-linear-gradient(#03D4C8,#008FD7);
    background: linear-gradient(#03D4C8,#008FD7);
}
.pricing-color:nth-child(3) .pricing-wrap{
    background: -webkit-linear-gradient(#008FD7,#00486C);
    background: -o-linear-gradient(#008FD7,#00486C);
    background: -moz-linear-gradient(#008FD7,#00486C);
    background: -mos-linear-gradient(#008FD7,#00486C);
    background: linear-gradient(#008FD7,#00486C);
}

.pricing-color:nth-child(4) .pricing-wrap{
    background: -webkit-linear-gradient(#008FD7,#4420BE,#224486);
    background: -o-linear-gradient(#008FD7,#4420BE,#224486);
    background: -moz-linear-gradient(#008FD7,#4420BE,#224486);
    background: -mos-linear-gradient(#008FD7,#4420BE,#224486);
    background: linear-gradient(#008FD7,#4420BE,#224486);
}

.pricing-color:nth-child(5) .pricing-wrap{
    background: -webkit-linear-gradient(#224486,#6200EE);
    background: -o-linear-gradient(#224486,#6200EE);
    background: -moz-linear-gradient(#224486,#6200EE);
    background: -mos-linear-gradient(#224486,#6200EE);
    background: linear-gradient(#224486,#6200EE);
}

.pricing-color:nth-child(2) .pricing-wrap .item-pricing-btn .el-button{
    background: #03D4C8;
    border-color: #03D4C8;
}
.pricing-color:nth-child(3) .pricing-wrap .item-pricing-btn .el-button{
    background: #008FD7;
    border-color: #008FD7;
}
.pricing-color:nth-child(4) .pricing-wrap .item-pricing-btn .el-button{
    background: #224486;
    border-color: #224486;
}
.pricing-color:nth-child(5) .pricing-wrap .item-pricing-btn .el-button{
    background: #6200EE;
    border-color: #6200EE;
}

.light-green-btn{
    background: #03D4C8;
    border-color: #03D4C8;
}

.light-green-btn{
    background: #03D4C8;
    border-color: #03D4C8;
}
.cambridge-blue-btn{
    background: #008FD7;
    border-color: #008FD7;
}
.dark-blue-btn{
    background: #004EE6;
    border-color: #004EE6;
}
.purple-btn{
    background: #6200EE;
    border-color: #6200EE;
}




.pricing-top{
    padding: 15px 10px 10px 10px;
}
.pricing-top p{
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
}
.pricing-content{
    padding: 15px 0px;
    background-color: #fff;
    border-radius: 0px 0px 15px 15px;
}
.item-pricing{
    font-size: 14px;
    line-height: 18px;
    padding: 0px 20px;
    font-weight: 400;
    color: #8F8E8E;
}
.item-pricing p{
    padding: 5px 0px;
    border-bottom: 1px solid #BFBFBF;
}
.item-pricing-btn{
    padding-top: 15px;
}
.top-up-content{
    position: relative;
}
.top-up-content img{
    width: 100%;
    height: auto;
    vertical-align: top
}
.top-up-btn{
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}
</style>

<style>
.credits-my .topup-dialog .el-dialog__header{
    display: none;
}
.credits-my .topup-dialog .el-dialog__body{
    padding: 0px 0px;
}
</style>
